import React, { forwardRef } from "react";
import { formatAmount } from "../../helpers/numbers";
import { capitalize } from "lodash";
import receiptHeader from "../../assets/receipt-header.png";

const PrintScreen = forwardRef(({ txData = {} }, printRef) => {

  const {
    amount,
    type,
    transaction_fee: transactionFee,
    created_at: createdAt,
    status,
    req: customer = {},
    res = {},
    transaction_reference: reference,
  } = txData;


  const {
    account_type: accountType = null,
    account = null,
    customer_address: customerAddress = null,
    name: customerName,
    service_type: serviceType,
    service_code: serviceCode,
  } = customer || {};


  const {
    token,
    kct1,
    kct2,
    units: unitPurchased,
  } = res?.info ?? (res ?? {});


  return (
    <div
      ref={printRef}
      style={{ maxWidth: "60%", display: "none", margin: "16px auto" }}
      className="bill-receipt yes-print"
    >
      <div>
        <img src={receiptHeader} width="100%" />
      </div>

      <div style={{ paddingInline: 32, marginTop: 24, }}>

        {amount && <div className="bill-receipt__row">
          <div className="field-label">Amount</div>
          <div className="field-value">{formatAmount(amount, '₦')}</div>
        </div>}

        {transactionFee && <div className="bill-receipt__row">
          <div className="field-label">Transaction Fee</div>
          <div className="field-value">{formatAmount(transactionFee, '₦')}</div>
        </div>}

        {serviceType && <div className="bill-receipt__row">
          <div className="field-label">Transaction Type</div>
          <div className="field-value">
            {capitalize(serviceType)}
          </div>
        </div>}

        {
          serviceCode && <div className="bill-receipt__row">
            <div className="field-label">Service</div>
            <div className="field-value">{serviceCode}</div>
          </div>
        }

        {
          accountType && <div className="bill-receipt__row">
            <div className="field-label">Account Type</div>
            <div className="field-value">{accountType}</div>
          </div>
        }

        {
          account && <div className="bill-receipt__row">
            <div className="field-label">Beneficiary</div>
            <div className="multi-value">
              <div className="field-value">{account}</div>
              <div className="sub-field-value">{customerName}</div>
              <div className="sub-field-value">{customerAddress}</div>
            </div>
          </div>
        }
        {
          token && <div className="bill-receipt__row">
            <div className="field-label">Token</div>
            <div className="field-value">
              <div className="row align-items-center">{token}</div>
            </div>
          </div>
        }

        {
          kct1 && <div className="bill-receipt__row">
            <div className="field-label">KCT 1</div>
            <div className="field-value">
              <div className="row align-items-center">{kct1}</div>
            </div>
          </div>
        }

        {
          kct2 && <div className="bill-receipt__row">
            <div className="field-label">KCT 2</div>
            <div className="field-value">
              <div className="row align-items-center">{kct2}</div>
            </div>
          </div>
        }

        {
          unitPurchased && <div className="bill-receipt__row">
            <div className="field-label">Units</div>
            <div className="field-value">
              <div className="row align-items-center">{unitPurchased}</div>
            </div>
          </div>
        }

        {createdAt && <div className="bill-receipt__row">
          <div className="field-label">Date & Time</div>
          <div className="field-value">{createdAt}</div>
        </div>}
        {
          reference && <div className="bill-receipt__row">
            <div className="field-label">Reference</div>
            <div className="field-value">{reference}</div>
          </div>
        }
        {
          status && <div className="bill-receipt__row">
            <div className="field-label">Status</div>
            <div className="field-value success">{status}</div>
          </div>
        }
      
        <div className="print-receipt-footer">
          <h1 style={{fontWeight: 700}}>NEED HELP?</h1>
          <p>For any other assistance, you can quickly reach us through <strong>{process.env.REACT_APP_CONTACT_EMAIL}</strong> and <strong>{process.env.REACT_APP_CONTACT_PHONE}</strong></p>
        </div>
      </div>
    </div>
  );
}
);

export default PrintScreen;

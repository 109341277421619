import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import TransactionPin from "../../ui/TransactionPin/TransactionPin";
import { useLocation, useNavigate } from "react-router-dom";
import SpLoadingOverlay from "../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import { encryptPin, getAccessToken, sleep } from './../../utils/utils';
import { initiateTransaction, resetTransactionInitState } from "../../redux/actions/transactions";
import { getNavPath } from "../../routes/routes.helpers";
import { NAV_ELECTRICITY } from "../../routes/routes.constant";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";

const AuthorizeTransactionWithPin = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const formData = location.state || {};
    const [pin, setPin] = useState("");
    const [isPinReset, setReset] = useState(false);

    const transactionInitState = useSelector((state) => state.transaction.transactionInit);

    const {
        //error: txInitError,
        loading: isLoading = false,
    } = transactionInitState ?? {};


    const handleSubmit = useCallback(async (pin) => {
        const payload = { ...formData, ['pin']: await encryptPin(pin, getAccessToken()) };
        setReset(false);

        await dispatch(initiateTransaction(payload))
            .then((response) => {
                message.success(response?.message ?? "Transaction initiated!");
                const state = { state: { transactionReference: response?.transaction_reference, ...response } }
                dispatch(resetTransactionInitState());

                sleep(500).then(() => {
                    navigate(`/${response?.req?.service_type?? getNavPath(NAV_ELECTRICITY)}/done`, state);
                });
            })
            .catch(error => {
                message.error(error);
                setReset(true);
            });

    }, [dispatch, formData, pin]);


    const onComplete = useCallback(
        (pin) => {
            handleSubmit(pin);
        },
        []
    );

    return (
        <SpLoadingOverlay isLoading={isLoading} style={{ padding: 0 }}>
            <SpPageTransition>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', columnGap: 24 }}>
                    <h3>Authorize Transaction</h3>
                    <p>Please enter your PIN to approve this transaction</p>
                    <div style={{ width: '310px' }}>
                        <TransactionPin initialPin={pin} reset={isPinReset} onComplete={onComplete} />
                    </div>
                </div>
            </SpPageTransition>
        </SpLoadingOverlay>
    );
}

export default AuthorizeTransactionWithPin;

import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useDispatch, useSelector } from 'react-redux';
import withUserData from "../../../HOC/withUserData";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import { authWithPin } from "../../../redux/actions/auth";
import { selectPinAuthLoading } from "../../../redux/selectors/auth";
import { encryptPin, getAccessToken, sleep } from "../../../utils/utils";
import { fetchUser } from "../../../redux/actions/user";

const PinAuthModal = ({ onClick, data, isLoggedIn }) => {
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [countdownTimer, setCountdownTimer] = useState(0);
  const location = useLocation();

  const pinAuthLoading = useSelector(selectPinAuthLoading);
  const isLoading = isLoggedIn && pinAuthLoading;

  const pinLength = 4;

  const handleSubmit = useCallback(async () => {
    if (pin?.length != pinLength) {
      message.error("Enter your 4-digit PIN.");
      return;
    }

    dispatch(authWithPin({ pin: await encryptPin(pin, getAccessToken()) })).then((res) => {
      message.success(res?.message ?? 'Account authenticated!');
      dispatch(fetchUser());
      
      sleep(1000).then(() => {
        onClick();
      });

    }).catch(err => {
      setPin('');
      message.error(err);
    });

  }, [pinLength, pin, data, onClick, location]);

  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);


  useEffect(() => {
    if (countdownTimer > 0) {
      const timerId = setTimeout(() => setCountdownTimer(prev => prev - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [countdownTimer]);

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Enter your PIN to continue</h3>
        <p>Session timed out due to inactivity.</p>
      </div>

      <div style={{
        marginTop: 42,
        marginBottom: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <OtpInput
          isInputSecure={true}
          isDisabled={isLoading}
          value={pin}
          onChange={setPin}
          numInputs={pinLength}
          className="otp-input"
          separator={<div style={{ marginRight: 15 }} />}
          shouldAutoFocus
          isInputNum
          onKeyUp={handleKeyUp}
        />
      </div>

      <div className="modal_components__button" style={{ paddingLeft: 32, paddingRight: 32 }}>
        <SpButton disabled={pin?.length !== pinLength} fullWidth={true} loading={isLoading} onClick={handleSubmit}>Re-gain Access</SpButton>
      </div>
    </div>
  );
};

export default withUserData(PinAuthModal);

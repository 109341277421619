import React, { useState } from "react";
import LceedataButton from "../../ui/Button/Button";
import apple from "../../assets/svgs/Apple.svg";
import google from "../../assets/svgs/Google Play.svg";
import banner from "../../assets/images/banner6.png";
import PayBills from "../../assets/svgs/svg-icons/PayBills";
import TrackExpenses from "../../assets/svgs/svg-icons/TrackExpenses";
import SendMoney from "../../assets/svgs/svg-icons/SendMoney";
import featureImg1 from "../../assets/images/feature-img1.png";
import featureImg2 from "../../assets/images/feature-img2.png";
import featureImg3 from "../../assets/images/feature-img3.png";
import eedc from "../../assets/logo/services/EEDC.png";
import dstv from "../../assets/logo/services/DSTV.png";
import ekedc from "../../assets/logo/services/EKEDC.png";
import geometric from "../../assets/logo/services/ABA POWER.png";
import ibedc from "../../assets/logo/services/IBEDC.png";
import ikeja from "../../assets/logo/services/IKEJA.png";
import swift from "../../assets/logo/services/Swift.png";
import neco from "../../assets/logo/services/NECO.png";
import waec from "../../assets/logo/services/WAEC.png";
import glo from "../../assets/logo/services/Glo.png";
import gotv from "../../assets/logo/services/GOTV.png";
import Chevron from "../../assets/svgs/svg-icons/Chevron";
import agent from "../../assets/images/Rectangle 13.png";
import RightIcon from "../../assets/svgs/svg-icons/RightIcon";
import SuccessIcon from "../../assets/svgs/svg-icons/SuccessIcon";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <section className="aboutUs__hero">
      <div className="aboutUs__hero-left">
        <div>
          <h1>Take charge  of your finances</h1>
          <p>
            Lceedata is a simple app that helps you pay bills, get on track with
            your finances, and Take charge  of your money.
          </p>
          <Link to={"/"}>
            <LceedataButton
              label={"Get started"}
              customClass={"aboutUs__hero-left-btn"}
            />
          </Link>
        </div>
        <div>
          <p>Coming soon...</p>
          <span>
            <button className="stores_btn" type="button">
              <img src={google} alt="/" />
            </button>
            <button className="stores_btn" type="button">
              <img src={apple} alt="/" />
            </button>
          </span>
        </div>
      </div>
      <div className="aboutUs__hero-right">
        <img src={banner} alt="/" />
        <span className="span1">
          <PayBills size={29.84} />
          <p>Pays Bills</p>
        </span>
        <span className="span2">
          <TrackExpenses size={29.84} />
          <p>Tracks your expenses</p>
        </span>
        <span className="span3">
          <SendMoney size={29.84} />
          <p>Send Money</p>
        </span>
      </div>
    </section>
  );
};

export const QuestionSection = () => {
  return (
    <section className="aboutUs__question-section">
      <div>
        <h3>What is lcee?</h3>
        <p>
          Lceedata is trendy for meaning a feeling of incapacity in financial
          matters. The name was adopted to show users that they can be in charge
          of their money, by helping them simplify complex financial services.
        </p>
      </div>
    </section>
  );
};

export const Features = () => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(0);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleClick = (index) => {
    setActive(index);
  };
  const handleImageLoad = () => {
    if (hovered) {
      const transition = document.getElementById("imgScale");
      transition.style.transition = "none";
      setHovered(false);
      setTimeout(() => {
        transition.style.transition = "all 0.7s ease-in-out";
        setHovered(true);
      }, 300);
    }
  };

  const content = [
    {
      title: "Pay all your bills in one place.",
      subtitle:
        "Say goodbye to bill juggling and hello to financial ease! Our fintech platform lets you pay all your bills in one place, effortlessly managing your finances with a smile.",
      badge: featureImg1,
      gradient: "blue-bg",
    },
    {
      title: "Follow your money and create a budget.",
      subtitle:
        "Empower your financial journey with our intuitive fintech platform, allowing you to easily track your money and Take charge  of your budget.",
      badge: featureImg2,
      gradient: "green-bg",
    },
    {
      title: "Payment made easy with the QR Code",
      subtitle:
        "Unlock the power of simplicity with our QR Code-based fintech solution. Effortlessly fund your wallet, send money, and pay bills with a quick scan, making payments a breeze.",
      badge: featureImg3,
      gradient: "pink-bg",
    },
  ];

  const labels = ["Pay Bills", "Track Finance", "QR Code"];
  const styles = {
    scale: hovered && "1.6",
    transform: hovered && "translateY(-11%)",
  };
  return (
    <section className="aboutUs__features">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="aboutUs__features-card"
      >
        <span className="aboutUs__features-card_tab-nav">
          {labels.map((label, index) => (
            <button
              onClick={() => handleClick(index)}
              className={active === index ? "active" : ""}
              key={index}
            >
              {label}
            </button>
          ))}
        </span>
        {content.map(({ title, subtitle, badge, gradient }, index) =>
          active === index ? (
            <div key={index} className="aboutUs__features-card_content">
              <span className="text-span">
                <h3>{title}</h3>
                <p>{subtitle}</p>
              </span>
              <span onLoad={handleImageLoad} className={`img-span ${gradient}`}>
                <img id="imgScale" src={badge} alt="/" style={styles} />
              </span>
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </section>
  );
};

export const ProvidersSection = ({headingColor = '#000'}) => {
  return (
    <section className="aboutUs__providers">
      <div>
        <h3 style={{color: headingColor}}>Service providers</h3>
        <span>
          <figure>
            <img src={eedc} alt="/" />
          </figure>
          <figure>
            <img src={dstv} alt="/" />
          </figure>
          <figure>
            <img src={ekedc} alt="/" />
          </figure>
          <figure>
            <img src={geometric} alt="/" />
          </figure>
          <figure>
            <img src={ibedc} alt="/" />
          </figure>
          <figure>
            <img src={ikeja} alt="/" />
          </figure>
          <figure>
            <img src={swift} alt="/" />
          </figure>
          <figure>
            <img src={neco} alt="/" />
          </figure>
          <figure>
            <img src={waec} alt="/" />
          </figure>
          <figure>
            <img src={glo} alt="/" />
          </figure>
          <figure>
            <img src={gotv} alt="/" />
          </figure>
        </span>
      </div>
    </section>
  );
};

export const Faqs = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleIconClick = (e, index) => {
    setActiveTab(index);
    const icon = document.getElementById(`Faqs${index + 1}`);
    if (icon) {
      icon.classList.toggle("active");
      e.currentTarget.classList.toggle("rotated");
    }
  };

  const accordion = [
    {
      title: "How do I register?",
      description: (
        <p>
          Explore Lceedata at{" "}
          <a href="https://www.bitanapay.com" target="_blank" rel="noreferrer">
            www.bitanapay.com
          </a>{" "}
          and effortlessly follow the provided steps. Embrace a world of
          financial possibilities with ease.
        </p>
      ),
      id: "Faqs1",
    },
    {
      title: "Can I use Lceedata on any device?",
      description:
        "Yes! You can use Lceedata anywhere, on your mobile or desktop device.",
      id: "Faqs2",
    },
  ];
  return (
    <section className="aboutUs__faqs">
      <div className="aboutUs__faqs_inner">
        <figure>
          <span>
            <SuccessIcon />
            <p>
              We are here to answer<b> all </b>your questions!
            </p>
          </span>
          <img src={agent} alt="/" />{" "}
        </figure>
        <div className="aboutUs__faqs_inner-right">
          <h3>You’ve got questions?</h3>
          {accordion.map(({ title, description, id }, index) => (
            <div
              className={
                activeTab === index
                  ? "aboutUs__faqs_inner-right-form active"
                  : "aboutUs__faqs_inner-right-form"
              }
              key={index}
              id={id}
              onClick={() => setActiveTab(index)}
            >
              <span>
                {" "}
                <h6>{title}</h6>
                <Chevron
                  className={activeTab === index ? "rotated" : ""}
                  onClick={(e) => {
                    handleIconClick(e, index);
                  }}
                />
              </span>
              <p>{description}</p>
            </div>
          ))}
          <a
            className="faqs-btn"
            href="https://bitanapay.tawk.help/"
            target="_blank"
            rel="noreferrer"
          >
            <button type="button">
              Learn more <RightIcon />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export const CTA = () => {
  return (
    <section className="aboutUs__Cta">
      <div>
        <h3>Pay your bills quickly and track your expenses today!</h3>
        <Link to={"/"}>
          Get Started <RightIcon />
        </Link>
      </div>
    </section>
  );
};

import React, { useCallback } from "react";
import { message } from "antd";
import CopyIcon from "../../assets/svgs/main-copy.svg";
import withUserData from "../../HOC/withUserData";
import SpButton from "../../sp-components/SpButton/SpButton";
import useSpModal from "../../sp-components/SpModal/SpModal";
import { formatAmount } from "../../helpers/numbers";
import { SpRefresh } from "../../components/Svgs";
import { useDispatch } from 'react-redux';
import { fetchUser } from "../../redux/actions/user";
import { sleep } from "../../utils/utils";

const DashboardInfo = ({
  virtualAccounts = {},
  wallet = {},
  userData = {},
  isLoggedIn
}) => {

  const accountName = userData?.displayName ?? '';
  const { requestVirtualAccount } = useSpModal();
  const dispatch = useDispatch();

  const {
    //account_name: accountName = '',
    account_number: accountNumber = "",
    bank_name: bankName = "",
  } = virtualAccounts[0] ?? {};

  const {
    main_balance: balance = 0,
  } = wallet ?? {};


  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(accountNumber);
    message.success("Copied to clipboard");
  }, [accountNumber]);

  const handleRequestVirtualAccount = () => {
    requestVirtualAccount();
  }

  return (
    <div className="row justify-space-between" style={{position: 'relative'}}>
      {
        !isLoggedIn && <div style={{ fontSize: 18 }}>Welcome back!</div>
      }
      {
        isLoggedIn && <div>
          <div className="sub-text mb-xs">Hi {accountName}!</div>
          <div className="amount-text">{formatAmount(balance, '₦')}</div>
          {accountNumber ? (
            <div className="account-text">
              {bankName} - <b>{accountNumber}</b>{" "}
              <img
                style={{ cursor: "pointer" }}
                className="ml-sm"
                src={CopyIcon}
                alt=""
                onClick={handleCopy}
              />
            </div>
          ) : <div style={{ marginTop: 16, }}><SpButton type="outline" onClick={handleRequestVirtualAccount}>Request Virtual Account</SpButton></div>}
        </div>
      }
      <span
        onClick={() => {
          message.loading({ content: 'Refreshing...', key: 'refresh' }); // Adding a unique key for consistent updates

          dispatch(fetchUser())
            .then(() => {
              sleep(1000).then(() => {
                message.success({ content: "Data refreshed successfully.", key: 'refresh' });
              });
            })
            .catch(() => {
              message.error({ content: "Unable to refresh data. Please try again.", key: 'refresh' });
            });
        }}
        style={{ cursor: 'pointer', position: 'absolute', top: 4, right: 0, }}
      >
        <SpRefresh />
      </span>

    </div>
  );
}

export default withUserData(DashboardInfo);

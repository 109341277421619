

import SpSummaryCard from "../../sp-components/SpSummaryCard/SpSummaryCard";
import SpCopy from "../../sp-components/SpCopy/SpCopy";
import { formatAmount } from "../../helpers/numbers";
import { capitalize } from "lodash";
import { formatDate } from "../../utils/utils";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";

const { Title: CardTitle, Text: CardText } = SpSummaryCard;

const SpReceipt = ({ txData = {} }) => {
    const {
        amount,
        //transaction_fee: transactionFee,
        service = {},
        created_at: createdAt,
        status,
        req: customer = {},
        res = {},
        transaction_reference: reference,
        remark = null,
        pre_balance: preBalance = null,
        post_balance: postBalance = null,
    } = txData || {};

    const {
        image_url: iconUrl = null,
    } = service || {};

    const {
        account_type: accountType = null,
        account = null,
        customer_address: customerAddress = null,
        name: customerName,
    } = customer || {};

    const {
        token,
        units: unitPurchased,
    } = res?.info ?? (res ?? {});

    return (
        <SpPageTransition>
            <div className="sp-transaction-card" id="transaction-receipt-wrapper">
                {iconUrl && (
                    <img
                        src={iconUrl}
                        alt={service}
                        style={{ width: 72, height: 72, borderRadius: "50%" }}
                    />
                )}

                {accountType && (
                    <div style={{ marginTop: 16 }}>
                        <span
                            style={{
                                display: "inline-block",
                                width: 84,
                                color: "#7535fd",
                                backgroundColor: "#f9f6ff",
                                textAlign: "center",
                                fontSize: 11,
                                lineHeight: "15.84px",
                                fontWeight: 500,
                                borderRadius: 26,
                                padding: "8px 16px",
                            }}
                        >
                            {accountType}
                        </span>
                    </div>
                )}

                

                {amount && (
                    <div style={{ marginTop: 20 }}>
                        <CardTitle>Amount</CardTitle>
                        <CardText>{formatAmount(amount, "₦")}</CardText>
                    </div>
                )}

                <div className="sp-transaction-card__content" style={{ marginTop: 24 }}>

                    
                {remark && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Remark</CardTitle>
                            </div>
                            <div style={{ textAlign: "right", maxWidth: 300 }}>
                                <CardText className={`font-size-14px`}>
                                    {remark}
                                </CardText>
                            </div>
                        </div>
                    )}

                    {account && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Beneficiary</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className="font-size-14px font-weight-semibold">{account}</CardText>
                                <CardTitle className="font-size-14px">
                                    <p>{customerName}</p>
                                    <p style={{
                                        maxWidth: 200,
                                        marginTop: -4,
                                    }}>{customerAddress}</p>
                                </CardTitle>
                            </div>
                        </div>
                    )}

                    {token && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Token</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className="font-size-14px font-weight-semibold">
                                    <SpCopy
                                        text={token}
                                        successMessage="Token copied!"
                                        errorMessage="Failed to copy token!"
                                    />
                                    {token}
                                </CardText>
                            </div>
                        </div>
                    )}

                    {unitPurchased && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Units Purchased</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className="font-size-14px font-weight-semibold">{unitPurchased}</CardText>
                            </div>
                        </div>
                    )}

                    {createdAt && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Date & Time</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className="font-size-14px font-weight-semibold">{formatDate(createdAt)}</CardText>
                            </div>
                        </div>
                    )}

                    {reference && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Reference</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className="font-size-14px font-weight-semibold">{reference}</CardText>
                            </div>
                        </div>
                    )}


                    {status && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Status</CardTitle>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <CardText className={`font-size-14px font-weight-semibold text-${status.toLowerCase()}`}>
                                    {capitalize(status)}
                                </CardText>
                            </div>
                        </div>
                    )}

                    
{preBalance && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Previous Balance</CardTitle>
                            </div>
                            <div style={{ textAlign: "right", maxWidth: 300 }}>
                                <CardText className={`font-size-14px`}>
                                    {formatAmount(preBalance, "₦")}
                                </CardText>
                            </div>
                        </div>
                    )}

                    {postBalance && (
                        <div className="sp-transaction-card__row">
                            <div>
                                <CardTitle className="font-size-14px font-weight-medium">Current Balance</CardTitle>
                            </div>
                            <div style={{ textAlign: "right", maxWidth: 300 }}>
                                <CardText className={`font-size-14px`}>
                                {formatAmount(postBalance, "₦")}
                                </CardText>
                            </div>
                        </div>
                    )}
                   
                </div>
            </div>
        </SpPageTransition>
    );
}

export default SpReceipt;
import PayElectricity from "../../assets/svgs/pay-electricity.svg";
import PayCable from "../../assets/svgs/pay-cable.svg";
import BuyAirtime from "../../assets/svgs/buy-airtime.svg";
import BuyInternet from "../../assets/svgs/buy-internet.svg";
import PayExams from "../../assets/svgs/pay-exams.svg";
import Others from "../../assets/svgs/Bag 1.svg";

// routes
import { NAV_ELECTRICITY } from "../../routes/routes.constant";

export const services = [
  {
    service: "electricity",
    label: "Pay Electricity",
    icon: PayElectricity,
    bgColor: "#7535fd",
    url: NAV_ELECTRICITY,
  },
  {
    service: "cable",
    label: "Pay CableTV",
    icon: PayCable,
    bgColor: "#F4FFF2",
    url: "",
    opacity: 0.3,
  },
  {
    service: "airtime",
    label: "Buy Airtime",
    icon: BuyAirtime,
    bgColor: "#EAF1FF",
    url: "",
    opacity: 0.3,
  },
  {
    service: "internet",
    label: "Buy Internet",
    icon: BuyInternet,
    bgColor: "#EAE9ED",
    url: "",
    opacity: 0.3,
  },
  {
    service: "exams",
    label: "Pay Exams",
    icon: PayExams,
    bgColor: "#FDF0EE",
    url: "",
    opacity: 0.3,
  },
  {
    service: "betting",
    label: "Other Collections",
    icon: Others,
    bgColor: "#FEEDFA",
    url: "",
    opacity: 0.3,
  },
];

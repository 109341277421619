
import SpButton from './../../sp-components/SpButton/SpButton';
import { PlusCircleOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons';
import { navigateTo } from './../../utils/utils';
import { getNavPath } from './../../routes/routes.helpers';
import { NAV_ACCOUNT, NAV_TRANSACTIONS } from './../../routes/routes.constant';
import useSpModal from '../../sp-components/SpModal/SpModal';

const ActionButtons = ({className=''}) => {
  const { requestVirtualAccount } = useSpModal();

  const handleClick =  (type) => {
    if (type === 'add_money') {
      requestVirtualAccount();
    }
    else if (type === 'view_transactions') {
      navigateTo(getNavPath(NAV_TRANSACTIONS));
    }
    else if (type === 'view_my_account') {
      navigateTo(getNavPath(NAV_ACCOUNT));
    }
    else {
      alert("Invalid action type '" + type + "' provided.");
    }
  }

  return (<div className={`${className + ' '}action-buttons`}>
    <SpButton onClick={() => handleClick('add_money')} type='default' shape="default" fullWidth={true} icon={<PlusCircleOutlined />}>Add Money</SpButton>
    <SpButton onClick={() => handleClick('view_transactions')} type='default' shape="default" fullWidth={true} icon={<HistoryOutlined />}>Transactions</SpButton>
    <SpButton onClick={() => handleClick('view_my_account')} type='default' shape="default" fullWidth={true} icon={<UserOutlined />}>My Account</SpButton>
  </div>);
}

export default ActionButtons;
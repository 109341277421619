import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Layout, Menu } from "antd";
import DashboardInfo from "./Dashboard.info";
import { useLocation, useNavigate } from "react-router-dom";
import { getNavPath } from "../../routes/routes.helpers";
import useAccountBalance from "../../hooks/useAccountBalance";
import withUserData from "../../HOC/withUserData";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceCategories } from "../../redux/actions/service";
import { resetTransactionFormData } from "../../redux/actions/transactions";

const { Content, Sider } = Layout;

const Dashboard = ({ children, account, userData = {}}) => {
  const { firstName } = userData;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { 
    data: categories, 
  } = useSelector(state => state.service.categories);


  const pathName =  location.pathname;
  const pathParts = pathName.split('/').filter(Boolean);

  const [activeKey, setActiveKey] = useState(pathParts[0] || '');

  const handleClick = useCallback(
    (e) => {
      dispatch(resetTransactionFormData());
      setActiveKey(e.key);
      navigate('/' + e.key);
    },
    [navigate, location]
  );

  useEffect(() => {
    const activeBasePathName = "/" + activeKey;

    if (pathName.startsWith(activeBasePathName)) {
      navigate(pathName);
    }
    else {
      navigate(getNavPath(activeKey));
    }
  }, [activeKey]);

  useEffect(() => {
    dispatch(fetchServiceCategories());
  }, []);

  const { accountDetails, accountBalance } = useAccountBalance();
  const localAccount = account || accountDetails;

  return (
    <Content className="dashboard">
      <Layout className="dashboard__layout">
        <Sider className="dashboard__side hide-side-menu">
          <div className="card">
            <DashboardInfo
              balance={accountBalance}
              username={firstName}
              bankName={localAccount?.bank_name}
              accountNumber={localAccount?.account_number}
            />
          </div>
          <br />
        
          <Menu
            mode="inline"
            className="dashboard__menu"
            selectedKeys={[activeKey]}
          >
            {categories.map(({ slug: index, name: label, image_url: imageUrl, status }) => (
              <Menu.Item
                className="dashboard__menu_item"
                icon={<img src={imageUrl} className={`service-img-icon ${activeKey === index? 'active' : ''}`} />}
                key={index}
                onClick={status === "active"? handleClick : null}
              >
                <Fragment>
                  {label}
                  {activeKey === index && (
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  )}
                </Fragment>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className="dashboard__content">{children}</Content>
      </Layout>
    </Content>
  );
};


export default withUserData(Dashboard);

import apiClient from "../api/apiClient";

export const initiateTransactionRequest = (data) => {
  const { pin, ...restData } = data || {};

  return apiClient({
    method: "POST",
    url: "/billers/transactions/initiate",
    data: restData,
    headers: {
      pin
    }
  });
}


export const fetchTransactionDetailsRequest = (transactionReference) => {

  return apiClient({
    method: "GET",
    url: "/transactions/" + transactionReference
  });
}

export const initiateGuestTransaction = (data) =>
  apiClient({
    method: "POST",
    url: "/guest/utility/electricity/initiateTransaction",
    data,
  });

export const resolveTransactionRequest = (txRef) =>
  apiClient({
    method: "POST",
    url: `/transactions/${txRef}/report`,
  });

  export const fetchTransactionsRequest = (page) => {
    return   apiClient({
      method: "POST",
      url: "/transactions?page=" + page,
      data: {
        shouldGroup: true
      }
    });
  }



import { message } from "antd";
import React, { useCallback, useState } from "react";
import LceedataInput from "../../../ui/Input/Input";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useDispatch, useSelector } from 'react-redux';
import withUserData from "../../../HOC/withUserData";
import useSpModal from "../../../sp-components/SpModal/SpModal";
import { fetchUser, requestAccount } from "../../../redux/actions/user";
import SpCopy from './../../../sp-components/SpCopy/SpCopy';

const RequestVirtualAccountModal = ({ onClick, virtualAccounts }) => {
  const [number, setNumber] = useState(null);
  const dispatch = useDispatch();
  const requestAccountState = useSelector((state) => state.user?.requestAccount);

  const { showOtp } = useSpModal();


  const {
    loading: isLoading = false,
  } = requestAccountState || {};

  const handleChange = useCallback((e) => setNumber(e.target.value), []);

  const handleSubmit = useCallback(() => {
    if (number?.length != 11) {
      message.error("Please check that the bank verification number is correct!");
      return;
    }

    const data = { identity_number: number, identity_type: 'BVN', otp: null };

    dispatch(requestAccount(data)).then((res) => {
      const otpIsRequired = res?.data?.otp_required ?? false;

      if (otpIsRequired) {
        message.success(res?.message ?? 'An OTP was sent to your phone.');
        showOtp({ data: data });
      }
      else {
        dispatch(fetchUser());
        message.success(res?.message ?? "Account created successfully");
        //onClick();
      }

    }).catch(err => {
      message.error(err);
    });

  }, [number, onClick]);


  if (virtualAccounts?.length > 0) {
    return (
      <div className="modal_components">
        <div>
          <div>
            <h5>Bank Name</h5>
            <h3>{virtualAccounts[0]?.bank_name}</h3>
          </div>
          <div>
            <h5>Account Name</h5>
            <h3>{virtualAccounts[0]?.account_name}</h3>
          </div>
          <div>
            <h5>Account Number</h5>
            <h3>{virtualAccounts[0]?.account_number} <SpCopy text={virtualAccounts[0]?.account_number} successMessage='Bank account number copied.' /></h3>
          </div>
        </div>
      </div>);
  }

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Create Virtual Account</h3>
        <p>Please enter your 11 digit Bank Verification Number</p>
      </div>

      <div style={{ marginTop: 42, marginBottom: 48 }}>
        <p>Bank Verification Number</p>
        <LceedataInput
          value={number}
          inputMode="numeric"
          onChange={handleChange}
          autoFocus
        />
      </div>

      <div className="modal_components__button">
        <SpButton fullWidth={true} loading={isLoading} onClick={handleSubmit}>Continue</SpButton>
      </div>
    </div>
  );
};

export default withUserData(RequestVirtualAccountModal);

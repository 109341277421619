import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpLoadingOverlay from './../../sp-components/SpLoadingOverlay/SpLoadingOverlay';
import { fetchTransactionList } from '../../redux/actions/transactions';
import Title from "antd/es/typography/Title";
import Chevron from '../../assets/svgs/svg-icons/Chevron';
import { formatDate } from '../../utils/utils';
import { formatAmount } from '../../helpers/numbers';
import { capitalize, isArray } from 'lodash';
import { EmptyState } from './heplers';
import LpButton from "../../ui/Button/Button";
import { useNavigate } from 'react-router-dom';
import { getNavPath } from '../../routes/routes.helpers';
import { NAV_TRANSACTIONS } from '../../routes/routes.constant';

const Transaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollContainerRef = useRef(null); // Reference for the scrollable container

  const {
    data: txData = {},
    loading: isLoading = false,
  } = useSelector(state => state.transaction.transactionList);

  const {
    data: txList = {},
    current_page: currentPage = 1,
    last_page: lastPage = null,
    total: totalItems,
  } = txData || {};

  // Function to load transactions
  const handleLoadTransactions = (pageOffset) => {
    const newPage = pageOffset === 0 ? 1 : currentPage + pageOffset; // Reset to page 1 if offset is 0
    dispatch(fetchTransactionList(newPage));
  };

  useEffect(() => {
    handleLoadTransactions(0); // Reset to page 1 on initial load
  }, []);

  useEffect(() => {
    // Scroll to the bottom when transactions are loaded
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [txList]); // Trigger scrolling when `txList` changes

  const handleViewReceipt = (id) => {
    navigate(getNavPath(NAV_TRANSACTIONS) + "/" + id);
  };

  return (
    <div className="services__transactions_container">
      <SpLoadingOverlay isLoading={isLoading} style={{ padding: 0 }}>
        <div className="bg-white" style={{ height: "530px" }}>
          <Title level={4}>Transactions</Title>
          {totalItems === 0 && <EmptyState />}

          <div
            ref={scrollContainerRef} // Attach the reference to the scrollable container
            style={{ marginTop: 32, maxHeight: "400px", overflow: "auto" }}
          >
            {
              totalItems > 0 && (
                <>
                  {Object.entries(txList).map(([created_at, transactions], key) => (
                    isArray(transactions) && (
                      <div className="transactions_card" key={key}>
                        <label>{formatDate(created_at)}</label>
                        {transactions.map((transaction, i) => (
                          <div
                            className={`transactions_card-items ${i !== 0 ? "with-border-top" : ""}`}
                            key={transaction.id || i}
                          >
                            <div className="transaction-label">
                              <span className="transaction-label-icon">
                                <img
                                  src={transaction?.service?.image_url}
                                  width={30}
                                  alt=""
                                  style={{ borderRadius: 50 }}
                                />
                              </span>
                              <span className="transaction-label-amount">
                                <p className="amount">{`₦ ${formatAmount(transaction.amount)}`}</p>
                                <span>
                                  <p className="transaction-id">
                                    {transaction.transaction_reference}
                                    <br />
                                    {transaction.account}
                                  </p>
                                </span>
                              </span>
                            </div>

                            <div className="transaction-status">
                              <span className={`status ${transaction.status.toLowerCase()}`}>
                                {transaction.status.toLowerCase() === "refund"
                                  ? "Refunded"
                                  : capitalize(transaction.status)}
                              </span>
                              <span onClick={() => handleViewReceipt(transaction.transaction_reference)}>
                                <Chevron />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  ))}
                </>
              )
            }
          </div>
          {currentPage >= lastPage && (
            <p
              style={{
                textAlign: "center",
                marginTop: "32px",
                fontSize: "14px",
                color: "#6c757d"
              }}
            >
              No more transactions
            </p>
          )}

          {currentPage < lastPage && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 32,
                height: 64,
                backgroundColor: "#fff",
              }}
            >
              <LpButton
                label="Load More Transactions"
                buttonType="bordered"
                customClass="loadmore-btn"
                loading={isLoading}
                onClick={() => {
                  handleLoadTransactions(1);
                }}
              />
            </div>
          )}
        </div>
      </SpLoadingOverlay>
    </div>
  );
};

export default Transaction;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";
import { Divider } from "antd";
import { Content } from "antd/es/layout/layout";

import _map from "lodash/map";
import ServiceTile from "../../ui/ServiceTile/ServiceTile";
import { services } from "./Homepage.helper";
import { fetchServiceCategories } from "../../redux/actions/service";
import SpHighlight from "../../sp-components/SpHighlight/SpHighlight";
import withUserData from "../../HOC/withUserData";
import SpWalletCard from "../../sp-components/SpWalletCard/SpWalletCard";
import SpLoadingOverlay from './../../sp-components/SpLoadingOverlay/SpLoadingOverlay';
import { resetTransactionFormData } from "../../redux/actions/transactions";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import ActionButtons from "../../components/ActionButtons/ActionButtons";


function Homepage({ isLoggedIn, userData, wallet }) {
  const dispatch = useDispatch();
  const { navigate, clearRedirect } = useCustomNavigate();

  const { data: categories, loading: isLoading } = useSelector(state => state.service.categories);

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(null);
  const [serviceList, setServiceList] = useState(services);


  const wordExists = useCallback((key, service) => {
    const regex = new RegExp(key, "i");
    return regex.test(service);
  }, []);

  useEffect(() => {
    dispatch(fetchServiceCategories());
    clearRedirect();
  }, []);

  useEffect(() => {
    if (value) {
      setServiceList(
        serviceList.filter(({ label }) => wordExists(value, label))
      );
      return;
    }
    if (value === "") {
      setServiceList(services);
    }
  }, [value, serviceList, wordExists]);

  const handleServiceClick = useCallback(
    (item, i) => {
      const { label, url, status } = item;

      if (status.toLowerCase() !== "active") {
        return;
      }

      dispatch(resetTransactionFormData());

      if (isLoggedIn) {
        navigate(url);
        return;
      }

      navigate('/login', {}, url);
      return;
    },
    [dispatch, navigate, isLoggedIn]
  );

  return (
    <SpLoadingOverlay isLoading={isLoading}>
      <div className="homepage" style={{ marginTop: 8 }}>
        <Content className="homepage__inner-container">
          <h5 style={{
            fontSize: 16,
            marginBottom: 14,
            fontWeight: 500,
            fontFamily: 'General Sans Medium'
          }}>Hello, {userData?.firstName?.length > 0 ? userData?.firstName : 'Guest'}! 👋</h5>
          
          <SpWalletCard wallet={wallet} />
          <ActionButtons />

          <div style={{ padding: "0px 16px" }}>
            <Divider />
          </div>
          <h4 className="homepage__title">
            Quick <SpHighlight>Access</SpHighlight>
          </h4>
          <div className="homepage__biller-grid">
            {_map(
              categories,
              ({ name: label, image_url: icon, bg_color: bgColor, slug: url, status }, i) => (
                <ServiceTile
                  key={i}
                  label={label}
                  icon={icon}
                  url={url}
                  bgColor={bgColor}
                  onClick={() => handleServiceClick({ label, url, status }, i)}
                  opacity={status.toLowerCase() === 'active' ? 0.8 : 0.3}
                />
              )
            )}
          </div>
        </Content>
      </div>
    </SpLoadingOverlay>
  );
}

export default withUserData(Homepage);

import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpLoadingOverlay from '../../sp-components/SpLoadingOverlay/SpLoadingOverlay';
import { fetchTransactionDetails} from '../../redux/actions/transactions';
import BillReceipt from './../../components/BillReceipt/BillReceipt';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'antd';
import { NAV_TRANSACTIONS } from '../../routes/routes.constant';
import { getNavPath } from '../../routes/routes.helpers';


const TransactionDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: txData = {},
    loading: isLoading = false,
  } = useSelector(state => state.transaction.transactionDetails);

  useEffect(() => {
    dispatch(fetchTransactionDetails(params.id))
  }, [params.id]);

  const handleBackNav = () => {
    navigate(-1);
  }

  return (
    <SpLoadingOverlay isLoading={isLoading}>
        <div className="bg-white" style={{height: isLoading? "500px" : "auto"}}>
          {txData?.user_id && <BillReceipt txData={txData} onClick={handleBackNav} />}
        </div>
      </SpLoadingOverlay>
  );
};

export default TransactionDetail;
